import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

import { toast } from "react-hot-toast";
import { createTemplate } from "../../utils/create-room";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Loader from "../../components/loaders/Loader";
import CreateSellerProfile from "./create/CreateSellerProfile";
import MyRoomsBrowser from "../rooms/MyRoomsBrowser";
import RoomBrowserCard from "../rooms/cards/RoomBrowserCard";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import useFacetFilterAPI from "../../services/useFacetFilterAPI";
import useDashApi from "../../services/useDashApi";
import { useAuth } from "../../context/AuthContext";

const NewTemplatePage = () => {
    const location = useLocation();
    const auth = useAuth();

    const [facet, isFacetLoading, facetError] = useFacetFilterAPI();

    const [
        sellerProfile,
        loadingSellerProfile,
        errorSellerProfile,
        refreshProfile,
    ] = useDashApi("templates/seller-me/", auth);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedResourceTypes, setSelectedResourceTypes] = useState([]);
    const [isPosting, setIsPosting] = useState(false);
    const [finishedScreen, setFinishedScreen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(
        location.state ? location.state : null
    );

    const [selectedImage, setSelectedImage] = useState(null);

    const { getAccessTokenSilently } = useAuth0();

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [selectedGrades, setSelectedGrades] = useState("");
    const [recommendedUsers, setRecommendedUsers] = useState("");
    const [showBrowser, setShowBrowser] = useState("");

    const handleChangeCategories = (selectedOption) => {
        setSelectedCategories(selectedOption);
    };

    const handleChangeGrades = (selectedOption) => {
        setSelectedGrades(selectedOption);
    };

    const handleChangeResourceTypes = (selectedOption) => {
        setSelectedResourceTypes(selectedOption);
    }
    const handleSubmit = (event) => {
        setIsPosting(true);
        let payload = new FormData();

        if (selectedImage !== null) {
            payload.append("image", selectedImage);
        }

        payload.append("title", title);
        payload.append("description", desc);
        payload.append("grades", JSON.stringify(selectedGrades));
        payload.append("recommended_users", recommendedUsers);
        payload.append("original_id", selectedRoom.id);
        payload.append("categories", JSON.stringify(selectedCategories));
        payload.append("resource_types", JSON.stringify(selectedResourceTypes));

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:classroom",
        }).then((token) => {
            createTemplate(payload, token)
                .then(() => {
                    setIsPosting(false);
                    setFinishedScreen(true);
                    console.log("Created successfully");
                })
                .catch((e) => {
                    setIsPosting(false);
                    toast.error(e);
                    console.log("Failed to create", e);
                });
        });
    };

    if (!auth.isProfileLoading && !auth.profile) {
        return (
            <p>
                You need to be signed in to submit your rooms to the Explore
                page.
            </p>
        );
    }

    if (loadingSellerProfile) {
        return <Loader />;
    }

    if (
        !errorSellerProfile &&
        !loadingSellerProfile &&
        sellerProfile === "No Profile"
    ) {
        return <CreateSellerProfile refreshProfile={refreshProfile} />;
    }

    if (finishedScreen) {
        return (
            <Container className="p-5 bg-light">
                <Alert variant="success">
                    <p>Your room has successfully been sent for review.</p>
                    <p className="mb-0">
                        We will let you know over email when your room is
                        approved and published.
                    </p>
                </Alert>
            </Container>
        );
    }

    console.log(selectedRoom);

    return (
        <Container className="p-5 bg-light">
            <Alert>
                When publishing a room for the Explore page, a copy of your
                original room will be used as the base for new rooms. <br />
                You can keep using your original room as normal. Please note
                that any changes will <span className="fw-bold">not</span> be
                reflected on your Explore Page listing.
            </Alert>
            <MyRoomsBrowser
                auth={auth}
                show={showBrowser}
                setShow={setShowBrowser}
                setRoom={setSelectedRoom}
                selectedRoom={selectedRoom}
                onFinished={(room) => {
                    console.log("Setting room to", room);
                    setSelectedRoom(room);
                    setShowBrowser(false);
                    console.log(selectedRoom);
                }}
                refreshProfile={refreshProfile}
            />
            <Card className="dash-card">
                <Tooltip id="info-form" />
                <Card.Header className="ps-4 bg-white">
                    <Card.Title className="pt-2">New Listing</Card.Title>
                </Card.Header>
                <Card.Body className="ps-4 pe-4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <p className="fw-bold">Step 1</p>{" "}
                        <p>Select the room you want to publish</p>
                        {selectedRoom ? (
                            <Row>
                                <Col xs={3}>
                                    <RoomBrowserCard
                                        noAction
                                        room={selectedRoom}
                                    />
                                    <Button
                                        className="mt-2"
                                        variant="secondary"
                                        onClick={() => setShowBrowser(true)}
                                    >
                                        Change
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Button
                                variant="purple-dark"
                                onClick={() => setShowBrowser(true)}
                            >
                                Select a Room
                            </Button>
                        )}
                    </Form.Group>
                </Card.Body>
                <hr />
                <Card.Body className="ps-4 pe-4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <p className="fw-bold">Step 2</p>
                        <p>Add the required information</p>
                        <Form.Label>
                            Title
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="The title of your room listing will be visible to others."
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        <Form.Control
                            required
                            autoFocus
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            type="text"
                            placeholder="Amazing Science Room"
                        />
                        <Form.Control.Feedback>
                            Looks Good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            You need a title for your room
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>
                            Description
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="The description helps users understand more about how to use your room"
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        <Form.Control
                            required
                            as={"textarea"}
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                            type="textarea"
                            placeholder="This room is focused on 4th grade math"
                        />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>
                            Recommended users
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="How many users would be ideal for this room? For example '2', '1-5' or 'Groups of 10'"
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        <Form.Control
                            required
                            value={recommendedUsers}
                            onChange={(e) =>
                                setRecommendedUsers(e.target.value)
                            }
                            type="text"
                            placeholder="1-5"
                        />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>
                            Suggested Grade Level
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="What grade is this room for? For example '4-6', '7', or 'Any'"
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        {!isFacetLoading && !facetError && (
                            <Select
                                isMulti
                                name="grades"
                                onChange={handleChangeGrades}
                                getOptionLabel={(grade) => grade.title}
                                getOptionValue={(grade) => grade.id}
                                options={facet.grades}
                            />
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>
                            Categories
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="Adding categories helps other users find your room. You may add multiple categories."
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        {!isFacetLoading && !facetError && (
                            <Select
                                isMulti
                                name="categories"
                                onChange={handleChangeCategories}
                                getOptionLabel={(category) => category.title}
                                getOptionValue={(category) => category.id}
                                options={facet.categories}
                            />
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>
                            Activities
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="What kind of activities this room is designed for?"
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        {!isFacetLoading && !facetError && (
                            <Select
                                isMulti
                                name="resourceTypes"
                                onChange={handleChangeResourceTypes}
                                getOptionLabel={(resourceType) => resourceType.title}
                                getOptionValue={(resourceType) => resourceType.id}
                                options={facet.resource_types}
                            />
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Banner Image
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="An image that will be displayed in the listing page"
                                data-tooltip-place="bottom"
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        <Form.Control
                            onChange={(event) => {
                                setSelectedImage(event.target.files[0]);
                            }}
                            type="file"
                            placeholder="Profile pic"
                        />
                    </Form.Group>
                </Card.Body>
                <Card.Footer className="ps-4 pe-4 p-4">
                    <Row>
                        <p>
                            Once clicking Submit, your listing will be sent to
                            our editor team for review.
                        </p>
                        <Col className="d-flex justify-content-between">
                            {title !== "" &&
                            desc !== "" &&
                            selectedImage !== null &&
                            selectedRoom !== null &&
                            selectedCategories.length !== 0  &&
                            selectedGrades.length !== 0  &&
                            recommendedUsers !== "" ? (
                                <Button
                                    variant="success"
                                    onClick={handleSubmit}
                                    disabled={isPosting}
                                >
                                    Submit for review
                                </Button>
                            ) : (
                                <Button disabled variant="success">
                                    Please fill out all the fields
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Container>
    );
};

export default NewTemplatePage;
