import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import AssignmentCard from "./cards/AssignmentCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { Socket } from "phoenix";

import SharedAssignmentsTab from "./tabs/SharedAssignmentsTab";
import MyStudentProjectsTab from "./tabs/MyStudentProjectsTab";
import ArchivedAssignmentsTab from "./tabs/ArchivedAssignmentsTab";
import ShowcasedAssignmentsTab from "./tabs/ShowcasedAssignmentsTab";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import useRoomAPI from "../../services/useRoomAPI";
import useSingleRoomAPI from "../../services/useSingleRoomAPI";

const AssignmentsContent = ({
    auth,
    restricted,
    rooms,
    newRoom,
    isLoadingRooms,
    roomError,
    showInviteModal,
    showStudentRoomsModal,
    showSharedRoomsModal,
    showArchivedRoomsModal,
    searchValue,
    setSearchValue,
    setSortValue,
    sortValue,
    shared_rooms,
    isLoadingShared,
    sharedError,
    refreshShared,
    archived_rooms,
    isLoadingArchived,
    archivedError,
    refreshArchived,
    showcased_rooms,
    isLoadingShowcased,
    showcasedError,
    refreshShowcased,
    refreshAllRooms,
}) => {
    const [socket, setSocket] = useState(null);
    const [tempSearch, setTempSearch] = useState("");
    const [page, setPage] = useState(1);

    useEffect(() => {
        const socketSettings = {};
        socketSettings.logger = (kind, msg, data) => {
            // console.log(kind, msg, data);
        };

        const socket = new Socket(
            process.env.REACT_APP_PHOENIX_ENDPOINT,
            socketSettings
        );
        socket.onError(async (error) => {
            // console.log("Socket errored", error);
        });

        socket.connect();
        setSocket(socket);
    }, [rooms]);

    const handleSort = (newSort) => {
        setPage(1);
        setSortValue(newSort);
    };

    const handleSearch = (value) => {
        setPage(1);
        setSearchValue(value);
    };

    return (
        <Container className="bg-light pe-2 ps-2 pe-md-4 ps-md-4">
            {restricted && (
                <Row className="m-3 mb-1">
                    <Col className="d-flex justify-content-start">
                        <Alert variant="warning">
                            <a href="/buy">Upgrade to Premium</a> to create
                            unlimited Lessons.
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className="mt-3 mb-3">
                <Col className="d-flex justify-content-start">
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder={"Search Student Projects"}
                            aria-label="Filter lessons by name"
                            value={tempSearch}
                            onChange={(e) => setTempSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch(tempSearch);
                                }
                            }}
                        />
                        <Button
                            variant="light border"
                            onClick={() => handleSearch(tempSearch)}
                        >
                            <FontAwesomeIcon icon={faSearch} fixedWidth />
                        </Button>
                        <DropdownButton
                            variant="light border"
                            as={ButtonGroup}
                            title={
                                <>
                                    <FontAwesomeIcon
                                        icon={faBarsStaggered}
                                        fixedWidth
                                    />
                                    <span className="ms-3 d-none d-md-inline">
                                        Sort
                                    </span>
                                </>
                            }
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                onClick={() => handleSort("title")}
                                eventKey="1"
                            >
                                {sortValue === "title" ? (
                                    <span>
                                        A-Z
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>A-Z</span>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleSort("-title")}
                                eventKey="1"
                            >
                                {sortValue === "-title" ? (
                                    <span>
                                        Z-A
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>Z-A</span>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleSort("-id")}
                                eventKey="1"
                            >
                                {sortValue === "-id" ? (
                                    <span>
                                        Newest
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>Newest</span>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleSort("id")}
                                eventKey="1"
                            >
                                {sortValue === "id" ? (
                                    <span>
                                        Oldest
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>Oldest</span>
                                )}
                            </Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>
                </Col>
            </Row>

            <Row className="mb-4 g-4">
                <Col className="">
                    <Tabs
                        defaultActiveKey="one"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="one" title="My Student Projects">
                            <MyStudentProjectsTab
                                auth={auth}
                                rooms={rooms}
                                showStudentRoomsModal={showStudentRoomsModal}
                                refreshShared={refreshShared}
                                refreshRooms={refreshAllRooms}
                                restricted={restricted}
                                newRoom={newRoom}
                                isLoadingRooms={isLoadingRooms}
                                roomError={roomError}
                                showInviteModal={showInviteModal}
                                handleSearch={handleSearch}
                                searchValue={searchValue}
                                setSortValue={setSortValue}
                                page={page}
                                setPage={setPage}
                                socket={socket}
                            />
                        </Tab>
                        <Tab
                            eventKey="my_studentprojects"
                            title="Shared with Me"
                        >
                            <SharedAssignmentsTab
                                auth={auth}
                                shared_rooms={shared_rooms}
                                showSharedRoomsModal={showSharedRoomsModal}
                                refreshRooms={refreshAllRooms}
                                restricted={restricted}
                                newRoom={newRoom}
                                isLoadingShared={isLoadingShared}
                                sharedError={sharedError}
                                showInviteModal={showInviteModal}
                                handleSearch={handleSearch}
                                searchValue={searchValue}
                                setSortValue={setSortValue}
                                page={page}
                                setPage={setPage}
                            />
                        </Tab>
                        <Tab
                            eventKey="my_archivedstudentprojects"
                            title="Archived"
                        >
                            <ArchivedAssignmentsTab
                                auth={auth}
                                archived_rooms={archived_rooms}
                                showArchivedRoomsModal={showArchivedRoomsModal}
                                refreshRooms={refreshAllRooms}
                                restricted={restricted}
                                newRoom={newRoom}
                                isLoadingArchived={isLoadingArchived}
                                archivedError={archivedError}
                                showInviteModal={showInviteModal}
                                handleSearch={handleSearch}
                                searchValue={searchValue}
                                setSortValue={setSortValue}
                                page={page}
                                setPage={setPage}
                            />
                        </Tab>
                        <Tab
                            eventKey="my_showcasedstudentprojects"
                            title="Showcased"
                        >
                            <ShowcasedAssignmentsTab
                                auth={auth}
                                showcased_rooms={showcased_rooms}
                                refreshRooms={refreshAllRooms}
                                restricted={restricted}
                                newRoom={newRoom}
                                isLoadingShowcased={isLoadingShowcased}
                                showcasedError={showcasedError}
                                showInviteModal={showInviteModal}
                                handleSearch={handleSearch}
                                searchValue={searchValue}
                                setSortValue={setSortValue}
                                page={page}
                                setPage={setPage}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

export default AssignmentsContent;
