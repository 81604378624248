import React, { useState, useEffect, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import logoMobile from "../logo-mobile.png";
import Container from "react-bootstrap/Container";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function MobileNav(props) {
    const { loginWithRedirect } = useAuth0();
    const [expanded, setExpanded] = useState(false);

    const handleNavigate = (to) => {
        console.log("Onselect triggered");

        navigate(to);
        setExpanded(false);
    };

    const ref = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (
                    event.target.id !== "toggler" &&
                    event.target.className !== "navbar-toggler-icon"
                ) {
                    setExpanded(false);
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <>
            <Navbar
                ref={ref}
                fixed="sticky"
                className="border-bottom d-sm-none navbar-expand-lg"
                expand="xs"
                expanded={expanded}
                collapseOnSelect
            >
                <Container fluid style={{ zIndex: 999 }}>
                    <Navbar.Brand
                        className="cursor-pointer"
                        onClick={() => handleNavigate("/")}
                    >
                        <img
                            src={logoMobile}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="MegaMinds Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        id="toggler"
                        aria-controls="basic-navbar-nav"
                        onClick={(e) => {
                            e.preventDefault();
                            setExpanded(!expanded);
                            return null;
                        }}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {props.isLoggedIn ? (
                            <Nav className="me-auto">
                                {props.profile &&
                                    props.profile.get_organization ===
                                        "admin" && (
                                        <Nav.Link
                                            onClick={() =>
                                                handleNavigate("/organization")
                                            }
                                        >
                                            Organization
                                        </Nav.Link>
                                    )}
                                <Nav.Link
                                    onClick={() => handleNavigate("/profile")}
                                >
                                    Profile
                                </Nav.Link>
                                <Nav.Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://megaminds.tawk.help"
                                >
                                    Help & Resources
                                </Nav.Link>
                                <Nav.Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://gomegaminds.com?utm_source=dashboard&utm_medium=referral&utm_content=aboutnav"
                                >
                                    About MegaMinds
                                </Nav.Link>
                            </Nav>
                        ) : (
                            <Nav className="me-auto">
                                <Button
                                    onClick={() => loginWithRedirect()}
                                    variant="purple-dark"
                                    className="me-3 ms-3 mb-2 mt-4"
                                >
                                    Sign in
                                </Button>
                                <Button
                                    onClick={() =>
                                        loginWithRedirect({
                                            authorizationParams: {
                                                screen_hint: "signup",
                                            },
                                        })
                                    }
                                    variant="purple-dark"
                                    className="me-3 ms-3"
                                >
                                    Sign up
                                </Button>
                                <Button
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://megaminds.tawk.help"
                                    variant="link"
                                    className="me-3 ms-3 mt-2"
                                >
                                    Help & Resources
                                </Button>
                                <Button
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://gomegaminds.com?utm_source=dashboard&utm_medium=referral&utm_content=aboutnav"
                                    variant="link"
                                    className="me-3 ms-3 mt-2"
                                >
                                    About MegaMinds
                                </Button>
                            </Nav>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {props.isLoggedIn && (
                <Navbar ref={ref} className="border-bottom d-sm-none">
                    <Container fluid style={{ zIndex: 999 }}>
                        <Nav variant="" className="">
                            <Nav.Item>
                                <Nav.Link
                                    active={pathname === "/lessons"}
                                    onClick={() => handleNavigate("/lessons")}
                                >
                                    Lessons
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    active={pathname === "/student-projects"}
                                    onClick={() =>
                                        handleNavigate("/student-projects")
                                    }
                                >
                                    Student Projects
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    active={pathname === "/explore"}
                                    onClick={() => handleNavigate("/explore")}
                                >
                                    Explore
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar>
            )}
        </>
    );
}
